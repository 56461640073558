import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'app-filter-items',
  templateUrl: './filter-items.component.html',
  styleUrls: ['./filter-items.component.scss']
})
export class FilterItemComponent implements OnInit {
  @Input() filters?: string[];
  @Input() activeFilters?: string[];
  @Output() filterClicked: EventEmitter<string> = new EventEmitter();

  constructor(private readonly projectsService: ProjectsService) { }

  isActive(filter: string): boolean {
    return this.activeFilters ? this.activeFilters.some(activeFilter => activeFilter === filter) : false;
  }

  ngOnInit(): void {
  }

}
