import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { SpecsComponent } from './components/specs/specs.component';
import { OverviewComponent } from './components/overview/overview.component';
import { FiltersComponent } from './components/overview/filters/filters.component';
import { ProjectCardComponent } from './components/overview/project-card/project-card.component';
import { FilterItemComponent } from './components/filter-items/filter-items.component';
import { MomentToYearPipe } from './pipes/moment-to-year.pipe';
import { ShuffleMovementPipe } from './directives/shuffle-movement.pipe';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AboutMeComponent } from './pages/about-me/about-me.component';
import { ProjectOverviewComponent } from './pages/project-overview/project-overview.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    ProjectDetailComponent,
    SpecsComponent,
    OverviewComponent,
    FiltersComponent,
    ProjectCardComponent,
    FilterItemComponent,
    MomentToYearPipe,
    ShuffleMovementPipe,
    NavigationComponent,
    AboutMeComponent,
    ProjectOverviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    TranslateModule.forRoot({}),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}