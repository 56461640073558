import { Component, HostListener, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  isOpen = false;
  activeLanguage?: string;
  onProjectDetailPage?: boolean;
  backIcon = faChevronLeft;
  @ViewChild('menu') menu?: ElementRef;
  @ViewChild('button') button?: ElementRef;

  constructor(private translateService: TranslateService, public router: Router) {
    this.activeLanguage = this.translateService.defaultLang;
  }

  isOnProjectDetailPage(url: string): boolean {
    return url.includes('/projects/');
  }

  changeLanguage(lang: 'nl' | 'en'): void {
    this.translateService.use(lang);
    this.activeLanguage = lang;
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Element): void {
    if (this.menu && this.button) {
      const clickedInside = this.menu.nativeElement.contains(targetElement);
      const clickedButton = this.button.nativeElement.contains(targetElement);
      if (!clickedInside && !clickedButton) {
        this.isOpen = false;
      }
    }
  }

}
