import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { slideInAnimation } from './animations/route.animations';
import { translations } from './translations/translations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent {
  title = 'portfolio';

  constructor(private translateService: TranslateService) {
    const language = navigator.language;

    if (language.includes('nl')) {
      this.translateService.setDefaultLang('nl');
    } else {
      this.translateService.setDefaultLang('en');
    }

    this.translateService.setTranslation('nl', translations.nl);
    this.translateService.setTranslation('en', translations.en);
  }

  prepareRoute(outlet: RouterOutlet): string {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
