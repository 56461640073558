import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { projects } from '../../projects';
import { Project } from '../../projects.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  activeProject?: Project;
  private activeId?: string;
  private activeLang: 'en' | 'nl' = (this.translateService.currentLang || this.translateService.defaultLang) as 'en' | 'nl';

  constructor(private readonly route: ActivatedRoute, private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(({ lang }) => {
      this.activeLang = lang as 'en' | 'nl';
      this.setActiveProject();
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.activeId = params.id;
      this.setActiveProject();
    });
  }

  private setActiveProject(): void {
    if (this.activeId) {
      this.activeProject = projects[this.activeLang][this.activeId];
    }
  }
}
