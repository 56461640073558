<div class="leader">
    <div class="content">
        <h1 translate>
            HELLO
        </h1>
        <h2>
            <span translate>INTRO.0</span>
            <a class="projects-link" [routerLink]="['/projects']" translate>INTRO.1</a>
            <span translate>INTRO.2</span>
        </h2>
        <ul class="list-unstyled class contact-links">
            <li (click)="copyEmail()" [class.copied]="copied" class="contact-link copy-link">
                <span>Email</span>
                <fa-icon [icon]="copyIcon"></fa-icon>
            </li>
            <li>
                <a class="contact-link" target="_blank" href="https://www.linkedin.com/in/else-ten-broeke/">
                    <span>LinkedIn</span>
                    <fa-icon [icon]="externalLinkIcon"></fa-icon>
                </a>
            </li>
            <li>
                <a class="contact-link" target="_blank" href="https://www.instagram.com/else_m/">
                    <span>Instagram</span>
                    <fa-icon [icon]="externalLinkIcon"></fa-icon>
                </a>
            </li>
        </ul>
    </div>
</div>