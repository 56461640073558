import { Component, Input, OnInit } from '@angular/core';
import { Project } from '../../../projects.type';
// import { projects } from '../../../projects';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
  animations: [
    // trigger('projectCards', [
    //   transition(':enter', [
    //     style({ height: '0px', width: '0px' }),  // initial
    //     query('.text-content', [
    //       style({ opacity: '0' }),  // initial
    //     ]),
    //     animate('0.4s',
    //       style({ height: '*', width: '*' })),  // final
    //     query('.text-content', [
    //       style({ opacity: '0' }),  // initial
    //       animate('0.3s',
    //         style({ opacity: '1' })),  // final
    //     ])
    //   ]),
    //   transition(':leave', [
    //     style({ height: '*', width: '*' }),  // initial
    //     query('.text-content', [
    //       style({ opacity: '1' }),  // initial
    //     ]),
    //     query('.text-content', [
    //       style({ opacity: '1' }),  // initial
    //       animate('0.3s',
    //         style({ opacity: '0' })),  // final
    //     ]),
    //     animate('0.4s',
    //       style({ height: '0px', width: '0px' })),  // final
    //   ]),
    // todo trigger if moves
    // ]),
  ]
})
export class ProjectCardComponent {
  @Input() project?: Project;
}
