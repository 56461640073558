import * as moment from 'moment';
import { Projects } from './projects.type';
import { Translations } from './translations/translate.type';


const projectsAllLanguage: Projects = {
    ['global-sites-app']: {
        id: 'global-sites-app',
        title: 'Global Sites',
        image: './assets/globalsites.png',
        description: [''],
        specs: {
            technologiesUsed: ['Angular', 'Angular Universal', 'HTML', 'SCSS', 'Spectator', 'Webdriver IO', 'Pivotal Cloud Foundry', 'Jenkins', 'Node.js', 'Javascript', 'RxJS'],
            company: 'Rabobank',
            website: {
                title: 'Rabobank',
                url: 'www.rabobank.nl/over-ons/rabofoundation'
            },
            timePeriod: [moment().year(2019), moment()],
        }
    },
    ['zorgzoeker']: {
        id: 'zorgzoeker',
        title: 'De zorgzoeker',
        image: './assets/zorgzoeker.png',
        description: [''],
        specs: {
            technologiesUsed: ['Angular', 'HTML', 'SCSS', 'Testbed', 'Cypress', 'RxJS'],
            company: 'VGZ',
            website: {
                title: 'Zorgzoeker',
                url: 'www.vgz.nl/zorg-regelen/zorgzoeker'
            },
            timePeriod: [moment().year(2018), moment().year(2019)],
        }
    },
    ['trustly']: {
        id: 'trustly',
        title: 'Trustly',
        image: './assets/trustly.png',
        description: [''],
        specs: {
            technologiesUsed: ['React', 'HTML', 'SCSS', 'Next.js'],
            company: 'Trustly',
            timePeriod: [moment().year(2019)],
        }
    },
    ['appointment-planner']: {
        id: 'appointment-planner',
        title: 'Appointment planner',
        image: './assets/meltech.png',
        video: './assets/meltech.mov',
        description: [''],
        specs: {
            technologiesUsed: ['Angular', 'HTML', 'SCSS', 'Node.js'],
            company: 'Meltech',
            timePeriod: [moment().year(2017)],
        }
    },
    ['momo-medical']: {
        id: 'momo-medical',
        title: 'Momo Medical',
        image: './assets/momo.png',
        description: [''],
        specs: {
            technologiesUsed: ['UX', 'Adobe Xd'],
            company: 'Momo Medical',
            timePeriod: [moment().year(2017), moment().year(2018)],
            website: {
                title: 'Tablet prototype',
                url: 'xd.adobe.com/embed/03ee8897-307b-4691-8e17-164d46dd4441/screen/11d4cc9f-4d64-489d-b66a-888e110745a8'
            }
        }
    },
    ['ihc']: {
        id: 'ihc',
        title: 'Royal IHC',
        image: './assets/ihc.png',
        description: [''],
        specs: {
            technologiesUsed: ['HTML', 'SCSS', 'Javascript'],
            website: {
                title: 'Royal IHC',
                url: 'www.royalihc.com'
            },
            company: 'Royal IHC',
            timePeriod: [moment().year(2015), moment().year(2016)],
        }
    }
};

// TODO fix duplicate id fiasco
// TODO add texts
export const projects: Translations<Projects> = {
    en: {
        ['global-sites-app']: {
            ...projectsAllLanguage['global-sites-app'],
            description: [`The issue Rabobank was having was: too many different websites, all with their own technologies and look and feel. To keep the control of the brand the idea of one application to be used for all anonymous websites was born. Global sites would be the name of the application. Global sites would become part of a frontend monorepo that is managed by NX. Build in Angular, using Angular Universal for server side rendering and using Spectator and Webdriver IO for automated testing. My role in this project was to help with the initial setup of the application and the CI/CD pipelines. Later on, besides maintaining the application and it’s pipelines, I also contributed in building multiple features and UI components that content editors could start using in pages.`],
        },
        ['trustly']: {
            ...projectsAllLanguage.trustly,
            description: [`Trustly needed a new marketing website to display their products. What was delivered was a headless CMS solution, using Bloomreach as a CMS and a React frontend. Additionally Next.js was used to be able to render the pages on the server. This ofcourse had some added benefits, with a consistent SEO performance being the most important to Trustly. My role during this project was mainly building the necessary components on the frontend in React, so that they could be added to pages in the CMS.`],
        },
        ['zorgzoeker']: {
            ...projectsAllLanguage.zorgzoeker,
            title: 'Find your healthcare provider',
            description: [`The Dutch healthcare insurance company VGZ wanted a tool that would make it easier for users to find the care they are looking for. Using Angular we built a search tool with which users can search for different healthcare providers and different types of care. By consuming multiple APIs we were able to search for both healthcare providers and healthcare types as well as the rating of said healthcare providers. The project had automated testing on both unit and integration level, using Angular’s testbed and Cypress.`],
        },
        ['appointment-planner']: {
            ...projectsAllLanguage['appointment-planner'],
            description: [`As part of a frontend traineeship the goal was to create an application to display what we had learned. Together with my fellow student Lauren we decided to build an app that enables customers to plan an appointment. On the admin side of the application, the admin was able to add possible time slots in a calendar, which were then stored in a MySQL database. On the client’s side of the app they could select one of these timeslots and fill in a form to make the appointment. Back on the admin side the details of this appointment would become visible. Have a look at the video below for a short demo.`],
        },
        ['momo-medical']: {
            ...projectsAllLanguage['momo-medical'],
            description: [
                `The goal of this project was to enable elderly people to be able to live at home as long as possible. By adding different sensors in the home, the homecare nurses will be informed of anytime the patient is in trouble. As well as the more immediate dangers, the app also enables nurses to look into different patterns that point to the situation of the patient worsening.`,
                `This project was my graduation project for my Communication & Multimedia Design studies. I started out with research and interviewing homecare nurses to come up with the concept. After designing a prototype in Adobe Xd the concept was validated by testing the prototype with actual users.`
            ],
        },
        ['ihc']: {
            ...projectsAllLanguage.ihc,
            description: [`During my internship at <a class="text-link" target="_blank" href="https://thevalley.nl">the Valley</a> I worked on a new website for IHC. With my Communication & Multimedia Design studies being focused on UX design, I had almost no prior frontend knowledge. My main role was to support in the HTML & CSS of the website, but I also touched & learned a little bit of JavaScript.`],
        },
    },
    nl: {
        ['global-sites-app']: {
            ...projectsAllLanguage['global-sites-app'],
            description: [`Het probleem wat Rabobank had was te veel verschillende websites, allemaal met hun eigen technologieën en look & feel. Om controle te houden over het merk ontstond het idee voor ‘Global Sites’. Dit was 1 applicatie welke voor alle anonieme websites van Rabobank gebruikt zou moeten worden. Global sites maakt onderdeel uit van een frontend monorepo, welke gemanaged wordt door NX. Global sites is gemaakt in Angular, gebruikt Angular Universal voor server side rendering. Spectator en Webdriver IO worden gebruikt voor de geautomatiseerde testen. Mijn rol in dit project was om de initiele versie van de app op te zetten, inclusief de CI/CD pipelines. Later heb ik ook aan meerdere UI components en features gewerkt, welke content editors konden gebruiken om op de pagina’s te zetten.`],
        },
        ['trustly']: {
            ...projectsAllLanguage.trustly,
            description: [`Trustly had een nieuwe marketing website nodig om hun producten weer te geven. Een headless CMS, met een Bloomreach CMS en een React frontend, werd opgeleverd. Daarnaast werd Next.js gebruikt om de pagina’s op de server te kunnen laden. Dit heeft een aantal voordelen, waaronder een consistente SEO performance voor Trustly erg belangrijk was. Mijn rol tijdens dit project was voornamelijk het implementeren van frontend componenten in React, welke vervolgens in het CMS weer aan pagina’s toegevoegd konden worden.`],
        },
        ['zorgzoeker']: {
            ...projectsAllLanguage.zorgzoeker,
            description: [`De zorgverzekeraar VGZ wou een tool implementeren waarmee gebruikers gemakkelijk de zorg en zorgverleners konden vinden waarnaar zij opzoek waren. Met behulp van Angular hebben wij deze tool geïmplementeerd. Door verschillende API’s te consumeren konden wij verschillende soorten zorg, zorgverleners en de scores horend bij de zorgverleners laten zien aan de gebruiker. Dit project bevatte ook automatische testen, zowel op unit test level, als op integratie test level. Hiervoor werdt Angular’s testbed gebruikt en Cypress. `],
        },
        ['appointment-planner']: {
            ...projectsAllLanguage['appointment-planner'],
            description: [`Als onderdeel van een frontend traineeship was het doel om te laten zijn wat wij geleerd hadden. Samen met mijn mede student Lauren besloten wij om een app te bouwen waarin afspraken ingepland konden worden. Aan de admin kant van de app kon de admin tijdslots toevoegen, welke vervolgens in een MySQL database werden opgeslagen. Vervolgens kon de klant op het klant gedeelte van de website een van de tijdslots selecteren en een formulier invullen om een afspraak in te plannen. Aan de admin kant werden de details van deze afspraak zichtbaar. Zie de video hieronder voor een kleine demonstratie.`],
            title: 'Afspraak planner',
        },
        ['momo-medical']: {
            ...projectsAllLanguage['momo-medical'],
            description: [
                `Het doel van dit project was om ouderen zo lang mogelijk de kans te bieden om thuis te blijven wonen. Door verschillende sensoren in het huis te plaatsen kunnen de thuiszorg verpleegkundigen een oogje in het zeil houden en worden zij gewaarschuwd als er iets mis is. Daarnaast kunnen ook patronen die er op duiden dat het slechter gaat met een patiënt eerder herkend worden.`,
                `Dit project was mij afstudeer project voor de opleiding Communication & Multimedia Design. Ik ben begonnen met thuiszorgverpleegkundigen te interviewen en onderzoek te doen om zo uiteindelijk tot dit concept te komen. Na de designs omgezet te hebben tot een prototype in Adobe Xd, is dit concept gevalideerd door  het prototype met gebruikers te testen.`
            ],
        },
        ['ihc']: {
            ...projectsAllLanguage.ihc,
            description: [`Tijden mij stage bij <a class="text-link" target="_blank" href="https://thevalley.nl">the Valley</a> heb ik meegeholpen met het bouwen van de vernieuwde IHC website. Mijn Communication & Multimedia Design studie was meer gefocust op UX design, waardoor ik nog weinig van frontend afwist. Tijdens dit project was mijn hoofdrol dan ook om support te bieden bij het bouwen van de HTML & CSS. Later kwam hier ook javascript bij kijken.`],
        },
    }
};

