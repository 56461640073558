import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import { Filters } from '../components/overview/overview.component';
import { projects } from '../projects';
import { Project, Projects, Technologies } from '../projects.type';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private projects: Projects = {};
  private readonly projectsSubject: ReplaySubject<Project[]> = new ReplaySubject();
  private activeFilters: Filters = {
    technologies: [],
    timePeriod: [],
  };
  private readonly activeFiltersSubject: ReplaySubject<Filters> = new ReplaySubject();
  private currentLang: 'en' | 'nl' = (this.translateService.currentLang || this.translateService.defaultLang) as 'en' | 'nl';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
  ) {
    this.projects = _.cloneDeep(projects[this.currentLang]);

    this.route.queryParams.subscribe(queryParams => {
      this.activeFilters.technologies = queryParams && queryParams.technologies ? queryParams.technologies.split(',') : [];
      this.filter();
      this.projectsSubject.next(this.getProjectsOrderedByDate());
      this.activeFiltersSubject.next(this.activeFilters);
    });

    this.translateService.onLangChange.subscribe(({ lang }) => {
      this.currentLang = lang as 'en' | 'nl';
      this.filter();
      this.projectsSubject.next(this.getProjectsOrderedByDate());
      this.activeFiltersSubject.next(this.activeFilters);
    });
  }

  getProjects(): Observable<Project[]> {
    return this.projectsSubject;
  }

  getActiveFilters(): Observable<Filters> {
    return this.activeFiltersSubject;
  }

  addFilter(filterClicked: any): void {
    if (this.activeFilters.technologies.some(tech => tech === filterClicked)) {
      this.activeFilters.technologies.splice(this.activeFilters.technologies.indexOf(filterClicked), 1);
    } else {
      this.activeFilters.technologies.push(filterClicked as Technologies);
    }
    this.updateQueryParams();
  }

  clearAllFilters(): void {
    this.activeFilters.technologies = [];
    this.updateQueryParams();
  }

  private getProjectsOrderedByDate(): any[] {
    return Object.entries(this.projects)
      .sort(([, left], [, right]) => {
        const leftDate = this.getAverageData(left.specs.timePeriod);
        const rightDate = this.getAverageData(right.specs.timePeriod);
        return moment.utc(rightDate).diff(moment.utc(leftDate));
      })
      .reduce((r, [k, v]) => ([...r, v]), [{}])
      .filter(project => !_.isEmpty(project));
  }

  private updateQueryParams(): void {
    const queryParams = {
      technologies: `${this.activeFilters.technologies.join(',')}`
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
  }

  private filter(): void {
    let activeProjects: Projects = {};

    // Find activeProjects
    if (!this.activeFilters.technologies.length) {
      activeProjects = _.cloneDeep(projects[this.currentLang]);
    } else {
      const mappedProjects: Projects = _.cloneDeep(projects[this.currentLang]);

      for (const projectKey in mappedProjects) {
        if (mappedProjects[projectKey]) {

          const isFilteredTechUsed = this.activeFilters.technologies.every(activeTechFilter => {
            return mappedProjects[projectKey].specs.technologiesUsed.some(techUsed => techUsed === activeTechFilter);
          });

          if (isFilteredTechUsed) {
            activeProjects[projectKey] = mappedProjects[projectKey];
          }
        }
      }
    }

    this.projects = activeProjects;
  }

  private getAverageData(dates: moment.Moment[]): moment.Moment {
    const maxDate = moment.max(dates);
    const minDate = moment.min(dates);
    const duration = moment.duration(maxDate.diff(minDate)).asDays();
    return moment(maxDate).subtract(duration, 'days');
  }
}
