import { Component, OnInit } from '@angular/core';
import { Project, Projects, technologies, Technologies } from '../../projects.type';
import { projects } from '../../projects';
import { Event, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../services/projects.service';
import { Observable } from 'rxjs';
import { enterAnimation, leaveAnimation } from './overview.animation';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  stagger,
  sequence,
} from '@angular/animations';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [
    trigger('projectCards', [
      // transition(':enter', enterAnimation),
      // transition(':leave', leaveAnimation),
    ])
  ]
})
export class OverviewComponent implements OnInit {
  projects$: Observable<Project[]> = this.projectsService.getProjects();
  projects?: Project[];
  activeFilters$: Observable<Filters> = this.projectsService.getActiveFilters();
  filters: Filters = {
    technologies: [...technologies],
  };
  navigatingTo?: number;

  constructor(private readonly projectsService: ProjectsService) { }

  ngOnInit(): void {
    this.projectsService.getProjects().subscribe(newProjects => {
      this.projects = newProjects;
    });
  }

  updateActiveFilters(filterClicked: any): void {
    this.projectsService.addFilter(filterClicked);
  }

  clearAllFilters(): void {
    this.projectsService.clearAllFilters();
  }

  startRouteAnimation(index: number): void {
    this.navigatingTo = index;
  }

  // trackItem(index: number, project: Project): string {
  //   return project.id;
  // }
}

export interface Filters {
  technologies: Technologies[];
  timePeriod?: string[];
}
