import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectOverviewComponent } from './pages/project-overview/project-overview.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { AboutMeComponent } from './pages/about-me/about-me.component';

const routes: Routes = [
  { path: 'projects', component: ProjectOverviewComponent, data: { animation: 'ProjectOverview' } },
  { path: 'projects/:id', component: ProjectDetailComponent, data: { animation: 'ProjectDetail' } },
  { path: '**', component: AboutMeComponent, data: { animation: 'Home' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
