<div>
    <h1 class="title" *ngIf="(activeFilters$ | async)?.technologies?.length === 1; else otherTitle">
        {{(activeFilters$ | async)?.technologies}} <span translate>WORKED_ON_LOWERCASE</span>
    </h1>

    <ng-template #otherTitle>
        <h1 class="title" translate>
            WORKED_ON
        </h1>
    </ng-template>

    <app-filters [filters]="filters" [activeFilters]="activeFilters$ | async"
        (activateFilter)="updateActiveFilters($event)" (clearFilters)="clearAllFilters()">
    </app-filters>

    <div class="projects" *ngIf="projects?.length; else noResults">
        <div *ngFor="let project of projects; let i = index" class="card-container">
            <app-project-card (mousedown)="startRouteAnimation(i)" [class.start-animation]="i === navigatingTo"
                *ngIf="project" [@projectCards] class="card" [project]="project">
            </app-project-card>
        </div>
    </div>

    <ng-template #noResults>
        <p class="no-results" translate>
            NO_RESULTS
        </p>
    </ng-template>
</div>