import { Translations } from 'src/app/translations/translate.type';

export const translations: Translations<any> = {
    en: {
        HELLO: 'Hi!',
        INTRO: [
            `I'm Else and I enjoy building captivating experiences on the web. I see you've made it onto my portfolio, feel free to have a look at some of the `, `projects`, ` I've worked on!`
        ],
        ABOUT_ME: 'About me',
        PROJECTS: `Projects`,
        WORKED_ON: `Projects I've worked on`,
        WORKED_ON_LOWERCASE: `projects I've worked on`,
        NO_RESULTS: `There are not results based on the current filters. Try adjusting the filters to get more results.`,
        START_FILTERING: `Filter on technologies`,
        CLEAR_FILTERS: `Clear all filters`,
        FILTERD_ON: `Filtered on:`,
        CLIENT: `Client`,
        TIMEFRAME: `Timeframe`,
        WEBSITE: `Website`,
        TECH_USED: `Technologies used`,
        PROTOTYPE: 'Prototype',
        PROJECT_OVERVIEW: 'Projectoverview',
    },
    nl: {
        HELLO: 'Hoi!',
        INTRO: [
            `Ik ben Else en ik vind het leuk om intrigerende ervaringen op het web te bouwen. Ik zie dat je op mijn portfolio bent terecht gekomen, kijk gerust eens naar de `, `projecten`, ` waar ik aan heb gewerkt!`
        ],
        ABOUT_ME: 'Over mij',
        PROJECTS: `Projecten`,
        WORKED_ON: `Projecten waar ik aan heb gewerkt`,
        WORKED_ON_LOWERCASE: `projecten waar ik aan heb gewerkt`,
        NO_RESULTS: `Er zijn geen resultaten gebaseerd op de huidige filters. Pas de filters aan om resultaten te krijgen.`,
        START_FILTERING: `Filter op technologiën`,
        FILTERD_ON: `Er is gefiltered op:`,
        CLEAR_FILTERS: `Verwijder alle filters`,
        CLIENT: `Klant`,
        TIMEFRAME: `Periode`,
        WEBSITE: `Website`,
        TECH_USED: `Gebruikte technologiën`,
        PROTOTYPE: 'Prototype',
        PROJECT_OVERVIEW: 'Projectoverzicht',
    },
};
