<ng-container *ngIf="specs">
    <div class="specs">
        <span class="name" translate>
            CLIENT
        </span>
        <span class="content">
            {{ specs.company }}
        </span>

        <span class="name" translate>
            TIMEFRAME
        </span>
        <span class="content">
            {{ specs.timePeriod | momentToYear }}
        </span>

        <ng-container *ngIf="specs.website">
            <span class="name" translate>
                WEBSITE
            </span>
            <span class="content">
                <a class="text-link" [href]="'https://' + specs.website.url" target="_blank">
                    {{ specs.website.title }}
                </a>
            </span>
        </ng-container>
    </div>
    <div class="name technologies-title" translate>
        TECH_USED
    </div>
    <div class="technologies">
        <a class="technology" *ngFor="let technology of specs.technologiesUsed" [routerLink]="['/projects']"
            [queryParams]="{technologies: technology}">{{technology}}</a>
    </div>
</ng-container>