import { animate, group, query, style, transition, trigger } from '@angular/animations';

const toDetail = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
    group([
        query(':leave', [
            group([
                query('app-project-card.start-animation', [
                    style({ transform: 'translateX(0)', zIndex: 99 }),
                    animate('500ms ease', style({ transform: 'translateX(calc(300% + 24px))' }))
                ], { optional: true }),
                animate('300ms ease', style({ opacity: 0 })),
            ])
        ], { optional: true }),
        query(':enter', [
            // initial
            style({
                transform: `translateX(-100%)`,
                opacity: 0,
            }),
            query('aside.specs, .main-content, .video-container', [
                style({ opacity: 0, transform: 'translateY(400px)' })
            ], { optional: true }),
            // animations
            animate(
                '400ms 500ms cubic-bezier(.25,.1,.25,1)',
                style({ transform: 'translateX(0%)', opacity: 1 }),
            ),
            query('aside.specs', [
                animate('500ms cubic-bezier(.25,.1,.25,1)', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            query('.main-content', [
                animate('300ms cubic-bezier(.25,.1,.25,1)', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            query('.video-container', [
                animate('300ms cubic-bezier(.25,.1,.25,1)', style({ opacity: 1, transform: 'translateY(0)' })),
            ], { optional: true }),
        ]),
    ]),
];

const detailToOverview = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
    group([
        query(':leave', [
            style({
                transform: `translateX(0)`,
                opacity: 1,
            }),
            animate(
                '500ms cubic-bezier(.25,.1,.25,1)',
                style({ transform: 'translateX(-200%)', opacity: 0, }),
            ),
        ]),
        query(':enter', [
            style({ opacity: 0, transform: 'translateX(calc(100%))', zIndex: 99 }),
            animate('600ms 150ms ease', style({ transform: 'translateX(0)', opacity: 1 }))

        ]),
    ]),
];

const homeToOverview = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
    group([
        query(':leave', [
            style({
                opacity: 1,
            }),
            animate(
                '200ms cubic-bezier(.25,.1,.25,1)',
                style({ opacity: 0, }),
            ),
        ]),
        query(':enter', [
            style({ opacity: 0, transform: 'translateX(calc(100%))', zIndex: 99 }),
            animate('500ms 150ms ease', style({ transform: 'translateX(0)', opacity: 1 }))

        ]),
    ]),
];

const toHome = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
    group([
        query(':leave', [
            style({
                opacity: 1,
            }),
            animate(
                '300ms cubic-bezier(.25,.1,.25,1)',
                style({ opacity: 0, }),
            ),
        ], { optional: true }),
        query(':enter', [
            // initial
            // page
            style({ opacity: 0, zIndex: 9 }),
            // intro text
            query('.leader', [
                style({ justifyContent: 'none', }),
                query('.leader .content', [
                    style({ opacity: `0` }),
                ]),
            ]),
            // buttons
            query('.contact-links', [
                query('li:nth-child(1)', [
                    style({ transform: `translateY(250%)`, opacity: 0 }),
                ]),
                query('li:nth-child(2)', [
                    style({ transform: `translateY(250%)`, opacity: 0 }),
                ]),
                query('li:nth-child(3)', [
                    style({ transform: `translateY(250%)`, opacity: 0 }),
                ]),
            ]),
            // animations
            // page
            animate('400ms cubic-bezier(.25,.1,.25,1)', style({ opacity: 1 })),
            // intro text
            query('.leader .content', [
                animate('400ms cubic-bezier(.25,.1,.25,1)', style({ opacity: `1` })),
                // animate('400ms cubic-bezier(.25,.1,.25,1)', style({ transform: `translateX(0)` })),
            ]),
            // buttons
            query('.contact-links', [
                query('li:nth-child(1)', [
                    animate('250ms 100ms cubic-bezier(.25,.1,.25,1)', style({ transform: `translateY(0)`, opacity: 1 })),
                ]),
                query('li:nth-child(2)', [
                    animate('250ms 100ms cubic-bezier(.25,.1,.25,1)', style({ transform: `translateY(0)`, opacity: 1 })),
                ]),
                query('li:nth-child(3)', [
                    animate('250ms 100ms cubic-bezier(.25,.1,.25,1)', style({ transform: `translateY(0)`, opacity: 1 })),
                ]),
            ])
        ]),
    ]),
];



export const slideInAnimation =
    trigger('routeAnimations', [
        transition('* => ProjectDetail', toDetail),
        transition('* => Home', toHome),
        transition('ProjectDetail => ProjectOverview', detailToOverview),
        // TODO
        transition('Home => ProjectOverview', homeToOverview),
    ]);
