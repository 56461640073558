export type Projects = {
    [key: string]: Project
};

export interface Project {
    id: string;
    title: string;
    description: string[];
    image: string;
    video?: string;
    specs: Specs;
}

export interface Specs {
    technologiesUsed: Technologies[];
    company: string;
    website?: {
        url: string;
        title: string;
    };
    timePeriod: moment.Moment[];
}

export const technologies = [
    'Angular',
    'React',
    'Spectator',
    'Webdriver IO',
    'Javascript',
    'HTML',
    'SCSS',
    'Pivotal Cloud Foundry',
    'Jenkins',
    'Testbed',
    'Cypress',
    'UX',
    'Node.js',
    'RxJS',
    'Adobe Xd',
    'Angular Universal',
    'Next.js',
] as const;

export type Technologies = typeof technologies[number];
