import { Component, OnInit } from '@angular/core';
import { faExternalLinkAlt, faClipboard, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent {
  externalLinkIcon = faExternalLinkAlt;
  copyIcon = faClipboard;
  copied = false;

  copyEmail(): void {
    const copyText = 'tenbroekeem@gmail.com';
    const dummyInput = document.createElement('input');
    document.body.appendChild(dummyInput);
    dummyInput.setAttribute('value', copyText);
    dummyInput.select();
    dummyInput.setSelectionRange(0, 99999);

    document.execCommand('copy');

    document.body.removeChild(dummyInput);

    this.copyIcon = faClipboardCheck;
    this.copied = true;
  }

}
