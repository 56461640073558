<div #filtersContainer [class.collapsed]="collapsed" [class.filtered]="activeFilters?.technologies?.length"
    class="technologies">
    <div class="container">
        <header #header (click)="toggleOpen()" class="header">
            <h2 *ngIf="!activeFilters?.technologies?.length; else filtered" class="title" translate>
                START_FILTERING
            </h2>
            <ng-template #filtered>
                <h2 class="title" translate>FILTERD_ON</h2>
                <span class="active-filters">
                    {{filterAsText(activeFilters?.technologies)}}
                </span>
            </ng-template>

            <button class="button">
                <fa-icon *ngIf="collapsed && activeFilters?.technologies?.length; else otherIcon" [icon]="editIcon">
                </fa-icon>

                <ng-template #otherIcon>
                    <fa-icon [icon]="hideIcon"></fa-icon>
                </ng-template>
            </button>
        </header>
        <div [class.collapsed]="collapsed" class="filter-items">
            <button *ngIf="activeFilters?.technologies?.length" class="clear-button" (click)="clearFilters.emit()"
                translate>
                CLEAR_FILTERS
            </button>
            <app-filter-items [activeFilters]="activeFilters?.technologies" [filters]="filters?.technologies"
                (filterClicked)="activateFilter.emit($event)">
            </app-filter-items>
        </div>
    </div>
</div>