<nav class="navigation">
    <a *ngIf="isOnProjectDetailPage(router.url)" data-text="back to project overview" [routerLink]="['/projects']"
        queryParamsHandling="merge" class="back-button">
        <fa-icon [icon]="backIcon" class="icon"></fa-icon>
        <span translate>
            PROJECT_OVERVIEW
        </span>
    </a>
    <button #button (click)="toggleOpen()" class="mobile-button">Menu</button>
    <ul #menu [class.open]="isOpen" class="list-unstyled nav-list">
        <li>
            <a (click)="toggleOpen()" class="link" [routerLink]="['/']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" translate>ABOUT_ME</a>
        </li>
        <li>
            <a (click)="toggleOpen()" class="link" [routerLink]="['/projects']" routerLinkActive="active"
                translate>PROJECTS</a>
        </li>
    </ul>
    <div class="language-list-item">
        <button [class.active]="activeLanguage === 'en'" class="language-button" (click)="changeLanguage('en')"
            data-text="EN">en</button>
        |
        <button [class.active]="activeLanguage === 'nl'" class="language-button" (click)="changeLanguage('nl')"
            data-text="NL">nl</button>
    </div>
</nav>