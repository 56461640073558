<div class="page" *ngIf="activeProject">
    <div class="teaser-info">
        <div class="image-container">
            <img class="image" alt="activeProject.title" [src]="activeProject.image">
        </div>
    </div>
    <div class="text-container container">
        <article class="main-content">
            <p *ngFor="let description of activeProject.description" [innerHTML]="description" class="info">
            </p>
        </article>
        <aside class="specs">
            <h1 class="title">{{activeProject.title}}</h1>
            <app-specs [specs]="activeProject.specs"></app-specs>
        </aside>
    </div>

    <div *ngIf="activeProject.video" class="video-container container">
        <video class="video" controls>
            <source [src]="activeProject.video" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</div>