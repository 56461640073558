import { Component, Input } from '@angular/core';
import { Specs } from '../../projects.type';

@Component({
  selector: 'app-specs',
  templateUrl: './specs.component.html',
  styleUrls: ['./specs.component.scss']
})
export class SpecsComponent {
  @Input() specs?: Specs;
}
