<a *ngIf="project" class="link" [routerLink]="['/projects', project?.id]" queryParamsHandling="merge">
    <picture class="picture">
        <img class="image" [alt]="project?.title" [src]="project?.image">
    </picture>
    <!-- TODO animation from the card opening into detail page -->
    <div class="text-container">
        <h2 class="text-content">{{project?.title}}</h2>
        <ul class="more-info list-unstyled text-content">
            <li>{{project?.specs?.company}}</li>
            <li>{{project?.specs?.timePeriod | momentToYear}}</li>
        </ul>
    </div>
</a>