import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'momentToYear'
})
export class MomentToYearPipe implements PipeTransform {

  transform(moments: moment.Moment[] | undefined): string {
    if (moments) {
      if (moments.length === 1) {
        return moments[0].format('YYYY');
      } else {
        const firstMoment = moment.min(moments);
        const lastMoment = moment.max(moments);

        return `${firstMoment.format('YYYY')} - ${lastMoment.format('YYYY')}`;
      }
    }

    return '';
  }

}
