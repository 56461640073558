import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Filters } from '../overview.component';
import { Technologies } from '../../../projects.type';
import { faEdit, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  @Input() filters?: Filters;
  @Input() activeFilters?: Filters | null;
  @Output() activateFilter: EventEmitter<string> = new EventEmitter();
  @Output() clearFilters: EventEmitter<void> = new EventEmitter();

  @ViewChild('header') header?: ElementRef;
  @ViewChild('filtersContainer') filtersContainer?: ElementRef;
  collapsed = true;
  editIcon = faEdit;
  hideIcon = faEyeSlash;

  toggleOpen(): void {
    if (this.header && this.filtersContainer && !this.collapsed && window.innerWidth > 768) {
      let width = 0;
      Array.from(this.header.nativeElement.children).forEach((el: any) => {
        if ((!this.activeFilters || !this.activeFilters.technologies.length) && el.tagName === 'BUTTON') {
          return;
        }

        width += el.offsetWidth;
      });

      this.filtersContainer.nativeElement.style.width = `${width + 42}px`;
    } else if (this.filtersContainer) {
      this.filtersContainer.nativeElement.style.width = `100%`;
    }

    this.collapsed = !this.collapsed;
  }

  filterAsText(technologies: Technologies[] | undefined): string {
    return technologies ? technologies.join(', ') : '';
  }
}
